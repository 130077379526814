function printReceiptData(button) {
  if(!button) return;
  
  button.addEventListener('click', openPrintDialog);
}

function openPrintDialog(e) {
  e.preventDefault();
  window.print();
}

export default printReceiptData;
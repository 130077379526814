function calculateTotal() {
  const productTotals = document.querySelectorAll('.result-container span');
  const totalContainer = document.querySelector('.total-container span');
  let initialValue = 0;
  let totalsArray = [];
  Array.from(productTotals).forEach(total => totalsArray.push(parseInt(total.innerText)));

  const sum = totalsArray.reduce((totalValue, currentValue) => {
    return totalValue + currentValue;
  }, initialValue);

  totalContainer.innerText = sum;
};

export default calculateTotal;
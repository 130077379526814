function getReceiptData() {
  if(window.location.pathname !== '/receipt') return;
  
  const container = document.querySelector('.content-container');

  const products = JSON.parse(window.localStorage.getItem('products'));
  const delimiter = 13; 
  const slicedProducts = products.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/delimiter);

    // If we got to the delimiter
    if(!resultArray[chunkIndex]) {

      // Start a new chunk
      resultArray[chunkIndex] = [];
    };

    // If we're at the delimiter or at a multiplier
    if(index === delimiter || index === (delimiter * 2) || index === (delimiter * 3) || index === (delimiter * 4) || index === (delimiter * 5) || index === (delimiter * 6) || index === (delimiter * 7) || index === (delimiter * 8) || index === (delimiter * 9) || index === (delimiter * 10) || index === (delimiter * 11) || index === (delimiter * 12) || index === (delimiter * 13) || index === (delimiter * 14) || index === (delimiter * 15)) {
      
      // Add an empty item
      resultArray[chunkIndex].push({
        id: 'empty',
        quantity: 1,
        name: 'empty'
      });
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);


  container.innerHTML = slicedProducts
    .map(productsArray => productsArray.map(product => 
      product.quantity > 0 &&
      `
        <tr>
          <td class="border-b border-t border-gray-100 p-4 text-gray-500" style="${product.name === 'empty' && 'color: white;'}">
            ${product.quantity}
          </td>
          <td colspan="2" class="border-b border-t border-gray-100 p-4 text-gray-500" style="${product.name === 'empty' && 'color: white;'}">
            ${product.name}
          </td>
        </tr>
      `)
      .join("")).join('');
};

export default getReceiptData;
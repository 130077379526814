import '../style.css';

import calculatePrice from './modules/calculatePrice';
import getReceiptData from './modules/getReceiptData';
import printReceiptData from './modules/printReceiptData';
import clearLocalStorage from './modules/clearLocalStorage';
import closeFlash from './modules/flashes';

calculatePrice(document.querySelectorAll('.row'));
printReceiptData(document.querySelector('.print'));
window.addEventListener('load', getReceiptData);
window.addEventListener('afterprint', clearLocalStorage);
closeFlash(document.querySelector('.close-notification'));
function closeFlash(button) {
  if(!button) return;
  
  button.addEventListener('click', handleCloseFlash);
}

const handleCloseFlash = (e) => {
  e.target.parentElement.remove();
}

export default closeFlash;
import calculateTotal from './calculateTotal';

function calculatePrice(rows) {
  if(!rows) return;
  
  rows.forEach(row => {
    const input = row.querySelector('input');
    const finalPrice = row.querySelector('.price-container span');
    const totalInput = row.querySelector('.result-container span');
    const productId = row.querySelector('.id-container');
    const productName = row.querySelector('.product-container');
    input.addEventListener('input', () => calculateProductTotal(input, finalPrice, totalInput, productId, productName));
  });
};

function calculateProductTotal(input, finalPrice, totalInput, productId, productName) {
  // Calculate product total
  const quantity = input.value;
  const productPrice = finalPrice.innerText;
  const totalPrice = quantity * productPrice;
  totalInput.innerText = totalPrice;
  
  // Store the product in localstorage
  const productObject = {
    id: productId.innerText,
    name: productName.innerText,
    quantity,
    productPrice:  productPrice,
    totalPrice,
  };
  storeProductInLocalStorage(productObject);

  // Calculate the grand total
  calculateTotal();
}

function storeProductInLocalStorage(product) {
  const productsArray = JSON.parse(window.localStorage.getItem('products')) || [];
  const isQuantityZero = product.quantity === 0 || product.quantity === null;
  
  // Check if the product is already on the products array. If so, update it
  const isProductInArray = productsArray.findIndex(productInArray => productInArray.id === product.id);
  if(isProductInArray > -1 || isProductInArray > -1 && isQuantityZero) {
    productsArray.splice(isProductInArray, 1);
  }

  productsArray.push(product);
  window.localStorage.setItem('products', JSON.stringify(productsArray));
}

export default calculatePrice;